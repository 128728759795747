export interface Messaging {
    listenToRenderCheckout: (eventProcessor: (event: CustomEvent) => void) => void;
    listenToUnmountCheckout: (eventProcessor: () => void) => void;
    listenToDisplayErrorMessages: (eventProcessor: (event: CustomEvent) => void) => void;
    listenToFinishContractRequest: (eventProcessor: () => void) => void;
}

export const messaging: Messaging = {
    listenToRenderCheckout: eventProcessor => {
        document.addEventListener('renderCheckout', eventProcessor as EventListener);
    },

    listenToDisplayErrorMessages: eventProcessor => {
        document.addEventListener('displayErrorMessages', eventProcessor as EventListener);
    },

    listenToUnmountCheckout: eventProcessor => {
        document.addEventListener('unmountCheckout', eventProcessor);
    },

    listenToFinishContractRequest: eventProcessor => {
        document.addEventListener('finishContractRequest', eventProcessor);
    }
};
