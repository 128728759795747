import * as React from 'react';
import SummaryList from '@eg/elements/SummaryList';
import { Tarifdaten } from '../types/Tarifdaten';

interface TarifdatenCompProps {
    tarifdaten: Tarifdaten;
}

const TarifdatenComp = (props: TarifdatenCompProps) => {
    return (
        <SummaryList>
            <dt>Versicherungsbeginn</dt>
            <dd>{props.tarifdaten.versicherungsBeginn}</dd>

            {props.tarifdaten.blocks && Object.keys(props.tarifdaten.blocks).map((key: string, index: number) => {
                return [
                    <dt key={`key-${index}`}>{key}</dt>,
                    <dd key={`value-${index}`}>{props.tarifdaten.blocks ? props.tarifdaten.blocks[key] : ''}</dd>
                ];
            })}
        </SummaryList>
    );
};

export default TarifdatenComp;
