import * as React from 'react';
import FormSection from '@eg/elements/FormSection';
import SummaryList from '@eg/elements/SummaryList';
import Button from '@eg/elements/Button';
import EditIcon from '@eg/elements/components/Icons/EditIcon';
import { Bankdaten } from '../types/Bankdaten';

interface BankdatenCompProps {
    kontoinhaber: string;
    data: Bankdaten;
}

// turn the iban to upper case remove all possible blanks and add them where they should be
const formatIban = (iban: string) => iban.toUpperCase().split(' ').join('').replace(/(.{4})/g, '$1 ');

const BankdatenComp = (props: BankdatenCompProps) => {
    return (
        <FormSection
            heading="Bankverbindung"
            data-component-id="block-bankverbindung"
        >
            <SummaryList>
                <dt>Zahlungsweise</dt>
                <dd>SEPA-Lastschriftmandat</dd>
                <dt>IBAN</dt>
                <dd data-component-id="summary-iban">{formatIban(props.data.iban)}</dd>
                {props.data.tagDerAbbuchung &&
                [
                    <dt key="tagDerAbbuchungLabel">Abbuchung am</dt>,
                    <dd key="tagDerAbbuchungValue">{props.data.tagDerAbbuchung}. des Fälligkeitsmonats</dd>
                ]
                }
                <dt>Kontoinhaber</dt>
                <dd>{props.kontoinhaber}</dd>
                {props.data.bic && [
                    <dt key="bicLabel">BIC</dt>,
                    <dd key="bicValue" data-component-id="summary-bic">{props.data.bic}</dd>
                ]
                }
            </SummaryList>
            <Button
                type="button"
                iconLeft={<EditIcon/>}
                variant="text-link"
                data-component-id={'CheckData-EditLinkForStep-Bankdaten'}
                onClick={props.data.onBankverbindungChanged}
            >
                Angaben ändern
            </Button>
        </FormSection>
    );
};

export default BankdatenComp;
