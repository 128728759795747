import { SchweigepflichtEntbindung, SchweigepflichtEntbindungData } from '../types/SchweigepflichtEntbindung';

export interface AppState {
    isIddErrorMessageDisplayed: boolean;
    triggerValidationOnFinish: boolean;
    bonitaetspruefungValid: boolean;
    iddDownloadClick: boolean;
    iddVerzichtCheckboxClick: boolean;
    bonipruefungValue: boolean;
    consents: boolean[];
    schweigepflichtEntbindung: SchweigepflichtEntbindungData;
}

export const createInitialState = (schweigepflichtEntbindung: SchweigepflichtEntbindung, consents?: boolean[]): AppState => {
    return {
        isIddErrorMessageDisplayed: false,
        triggerValidationOnFinish: false,
        bonitaetspruefungValid: true,
        bonipruefungValue: false,
        consents: consents || [],
        iddDownloadClick: false,
        iddVerzichtCheckboxClick: false,
        schweigepflichtEntbindung: schweigepflichtEntbindung.input || {}
    };
};
