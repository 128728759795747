import * as React from 'react';
import FormSection from '@eg/elements/FormSection';
import config from './config';
import { Versicherungsnehmer } from './types/Versicherungsnehmer';
import VersicherungsnehmerComp from './components/VersicherungsnehmerComp';
import BankdatenComp from './components/BankdatenComp';
import HeaderInfo from './components/HeaderInfo';
import BeitragsdatenComp from './components/BeitragsdatenComp';
import { PdfRequest } from '@eg/idd-components';
import { Messaging } from './messaging/messaging';
import { Block } from './types/Block';
import TarifdatenComp from './components/TarifdatenComp';
import { Tarifdaten } from './types/Tarifdaten';
import { Beitragsdaten } from './types/Beitragsdaten';
import { Callbacks } from './messaging/model/Callbacks';
import BlockComp from './components/BlockComp';
import MountingPointComp from './components/MountingPointComp';
import { ExternalSubcomponent } from './types/ExternalSubcomponent';
import { AppState, createInitialState } from './state/AppState';
import mapConsents from './messaging/inputMapper';
import { Consent } from './messaging/model/Consent';
import * as stateFunctions from './state/stateFunctions';
import { getValid } from './state/stateFunctions';
import { getValidityOfConsents } from './state/helperFunction';
import mapFieldErrors from './mapper/fieldErrorMapper';
import { Tracking } from './messaging/model/Tracking';
import FieldError from './types/FieldError';
import Provider, { THEME } from '@eg/elements/Provider';
import { Sparte } from './types/Sparte';
import { ConsentBox } from './components/ConsentBox';
import { SchweigepflichtEntbindungComp } from './components/SchweigepflichtEntbindungComp';
import Idd from './components/Idd';
import AnnotationPortal from './components/AnnotationPortal';
import { SchweigepflichtEntbindung, SchweigepflichtEntbindungData } from './types/SchweigepflichtEntbindung';
import callOptionalFunction from './utility/FunctionHelper';
import DocumentDownload from './components/DocumentDownload'
import { IddMock } from './mocks/IddMock'
import { DocumentDownloadInput } from './types/DocumentDownload'
import { validateIddConfiguration } from './utility/helpers';

export interface AppProps {
    theme: THEME;
    versicherungsnehmer: Versicherungsnehmer;
    tarifdaten: Tarifdaten;
    beitragsdaten: Beitragsdaten;
    messaging: Messaging;
    pdfRequest: PdfRequest;
    documentDownload?: DocumentDownloadInput // when set, idd service won't be used anymore, downloading pdf is an client responsibility
    blocks?: Block[];
    extSubcomponents?: ExternalSubcomponent[];
    callbacks: Callbacks;
    tracking?: Tracking;
    consents?: Consent[];
    sparte: Sparte;
    schweigepflichtEntbindung: SchweigepflichtEntbindung;
    antrag: boolean;
    iddWithoutFormsection: boolean;
    backendHost?: string;
    iddBackendHost?: string;
}

const getKontoinhaber = (vn: Versicherungsnehmer): string => {
    return `${vn.anrede} ${vn.vorname} ${vn.nachname}`;
};

class App extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);

        validateIddConfiguration(config.mockIddComponents, !!props.documentDownload, props.iddBackendHost);
        const mappedConsents = props.consents ? mapConsents(props.consents) : undefined;
        this.state = createInitialState(props.schweigepflichtEntbindung, mappedConsents);
    }

    handleIddValidatityChanged = (isCheckboxClicked: boolean, isDocumentDownloaded: boolean) => {
        const touchedFields: string[] = [];
        if (isCheckboxClicked !== this.state.iddVerzichtCheckboxClick) {
            touchedFields.push('IDD-Checkbox');
        }
        if (isDocumentDownloaded !== this.state.iddDownloadClick) {
            touchedFields.push('IDD-Herunterladen');
        }

        this.setState({
            iddDownloadClick: isDocumentDownloaded,
            iddVerzichtCheckboxClick: isCheckboxClicked
        },            () => {
            this.reportValidity(touchedFields);
        });
    }

    onFocusField = (fieldId: string) => {
        if (this.props.tracking && this.props.tracking.onFocusField) {
            this.props.tracking.onFocusField(fieldId);
        }
    }

    handleConsentChanged = (key: number, valid: boolean) => {
        this.setState(state => stateFunctions.setConsentValidity(state, key, valid), () => {
            this.reportValidity([(this.props.consents as Consent[])[key].title.replace(/ /g, '')]);
        });
    }

    handleSchweigepflichtEntbindungChanged = (data: SchweigepflichtEntbindungData) => {
        this.setState({schweigepflichtEntbindung: data}, () => {
            this.reportValidity([]);
            this.onChange();
        });
    }

    checkValiditySchweigepflichtEntbindung = (): boolean => {
        return !this.props.schweigepflichtEntbindung.display ||
            !!this.state.schweigepflichtEntbindung.schweigepflichtEntbindungTyp;
    }

    reportValidity = (touchedFields: string[]) => {
        this.props.callbacks.onReportValidity(
            getValidityOfConsents(this.state.consents)
            && this.state.iddDownloadClick
            && this.state.iddVerzichtCheckboxClick
            && this.checkValiditySchweigepflichtEntbindung()
        );

        if (this.props.tracking) {
            if (this.props.tracking.onFocusField) {
                touchedFields.forEach((touchedField: string) => {
                    this.onFocusField(touchedField);
                });
            }
            if (this.props.tracking.onDisplayFieldError) {
                let errorOb: FieldError[] = mapFieldErrors(this.state, this.props.consents);
                if (errorOb.length > 0) {
                    this.props.tracking.onDisplayFieldError(errorOb);
                }
            }
        }
    }

    renderIddBlock = (): JSX.Element => {
        if (config.mockIddComponents) return (<IddMock onValidate={this.handleIddValidatityChanged} onError={this.handleError}></IddMock>);
        
        if (!this.props.iddBackendHost) {
            console.warn("Warning! Rendering the Idd component with iddBackendHost missing, please provide it to make IDD work correctly!");
        }

        const iddBlock =  (
            <Idd
                onValidate={this.handleIddValidatityChanged}
                onError={this.handleError}
                isIddErrorMessageDisplayed={this.state.isIddErrorMessageDisplayed}
                pdfRequest={this.props.pdfRequest}
                antrag={this.props.antrag}
                onCheckedBeratungsverzicht={this.props.callbacks.onCheckedBeratungsverzicht}
                onDownloadedPdf={this.props.callbacks.onIddPdfDownloaded}
                backendHost={this.props.iddBackendHost}
            />
        );
        if (this.props.iddWithoutFormsection) {
            return iddBlock;
        } else {
            return <FormSection data-component-id="block-idd">{iddBlock}</FormSection>;
        }
    }

    validateOnFinish = () => {
        this.setState({
            triggerValidationOnFinish: true,
            isIddErrorMessageDisplayed: true
        });
    }

    handleDisplayErrorMessages = () => {
        this.setState({isIddErrorMessageDisplayed: true});
    }

    handleError = (e: Error) => {
        this.props.callbacks.onError(e);
    }

    onChange = () => {
        if (this.props.callbacks.onChange) {
            this.props.callbacks.onChange({
                schweigepflichtEntbindung: this.state.schweigepflichtEntbindung
            });
        }
    }

    componentDidMount() {
        this.props.messaging.listenToDisplayErrorMessages(this.handleDisplayErrorMessages);
        this.props.messaging.listenToFinishContractRequest(this.validateOnFinish);
        if (this.props.callbacks.onRenderComplete) {
            this.props.callbacks.onRenderComplete();
        }
    }

    render() {
        const theme = this.props.theme || ('ergodirekt' as THEME);
        const vn: Versicherungsnehmer = this.props.versicherungsnehmer;

        return (
            <Provider theme={theme}>
                <HeaderInfo anrede={vn.anrede} titel={vn.titel} vorname={vn.vorname} nachname={vn.nachname} sparte={this.props.sparte} antrag={this.props.antrag}/>
                <FormSection
                    heading="Tarifdaten und Beitrag"
                    data-component-id="block-tarifdaten-und-fee"
                >
                    <TarifdatenComp tarifdaten={this.props.tarifdaten}/>
                    <BeitragsdatenComp data={this.props.beitragsdaten}/>
                </FormSection>

                <VersicherungsnehmerComp data={this.props.versicherungsnehmer}/>

                {this.props.blocks &&
                <>
                    {this.props.blocks.map(value => <BlockComp block={value} key={value.name}/>)}
                </>
                }

                <BankdatenComp
                    data={this.props.versicherungsnehmer.bankdaten}
                    kontoinhaber={getKontoinhaber(this.props.versicherungsnehmer)}
                />

                {this.props.extSubcomponents &&
                <>
                    {this.props.extSubcomponents.map(value => <MountingPointComp
                        key={value.mountingPoint}
                        headline={value.headline}
                        mountingPoint={value.mountingPoint}
                        onEdit={value.onChangeData}
                    />)}
                </>
                }

                {this.props.schweigepflichtEntbindung.display &&
                <SchweigepflichtEntbindungComp
                    schweigepflichtEntbindung={this.state.schweigepflichtEntbindung}
                    onChange={this.handleSchweigepflichtEntbindungChanged}
                    triggerValidation={this.state.triggerValidationOnFinish}
                    callbacks={this.props.callbacks}
                />
                }

                {this.props.consents &&
                <>
                    {this.props.consents.map((c: Consent, index: number) =>
                        <ConsentBox
                            key={index}
                            index={index}
                            title={c.title}
                            text={c.text}
                            errorMsg={c.errorMsg}
                            valid={getValid(this.state.consents, index)}
                            onCheck={(key, value) => {
                                this.handleConsentChanged(key, value);
                                callOptionalFunction(c.onInteraction);
                            }}
                            triggerValidation={this.state.triggerValidationOnFinish}
                        />
                    )}
                </>
                }
                {!!this.props.documentDownload ? (
                    <DocumentDownload
                      onValidate={this.handleIddValidatityChanged}
                      downloadButtonLoading={this.props.documentDownload.downloadButtonLoading}
                      downloadButtonDisabled={this.props.documentDownload.downloadButtonDisabled}
                      onDownloadButtonClick= {this.props.documentDownload.onDownloadButtonClick}/>
                ) : (
                    this.renderIddBlock()
                )}
                {this.props.beitragsdaten.fussnote && <AnnotationPortal fussnote={this.props.beitragsdaten.fussnote}/>}
            </Provider>
        );
    }
}

export default App;
