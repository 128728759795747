import FieldError from '../types/FieldError';
import { Consent } from '../messaging/model/Consent';
import { getValid } from '../state/stateFunctions';
import { AppState } from '../state/AppState';

const mapFieldErrors = (state: AppState, consents?: Consent[]): FieldError[] => {
    let errorOb: FieldError[] = [];
    if (!state.iddDownloadClick) {
        errorOb.push({fieldId: 'iddDownload', msg: 'noDownload'});
    }
    if (!state.iddVerzichtCheckboxClick) {
        errorOb.push({fieldId: 'iddVerzichtCheckbox', msg: 'noVerzicht'});
    }

    if (consents) {
        consents.forEach((consent: Consent, index: number) => {
            if (!getValid(state.consents, index)) {
                errorOb.push({
                    fieldId: consent.title.replace(/ /g, ''),
                    msg: consent.errorMsg
                });
            }
        });
    }
    return errorOb;
};

export default mapFieldErrors;