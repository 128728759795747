import * as React from 'react';
import * as ReactDOM from 'react-dom';
import AnnotationContainer from './AnnotationContainer';

export interface AnnotationPortalProps {
    fussnote: string;
}

class AnnotationPortal extends React.Component<AnnotationPortalProps> {

    render() {
        const container = document.getElementById('annotation-text') as HTMLElement;

        if (container === null) {
            return null;
        }

        return ReactDOM.createPortal(
            <AnnotationContainer
                annotations={[this.props.fussnote]}
            />,
            container
        );
    }
}

export default AnnotationPortal;
