import React, { useEffect, useState } from 'react'
import Checkbox from '@eg/elements/Checkbox';
import MessageBox from '@eg/elements/MessageBox';
import StepButton from '@eg/elements/StepButton';
import { DocumentDownloadInput } from '../types/DocumentDownload'

export interface DocumentDownloadProps extends DocumentDownloadInput {
  onValidate: (isCheckboxChecked: boolean, isButtonClicked: boolean) => void
}

const DocumentDownload = ({
  onValidate,
  downloadButtonLoading,
  downloadButtonDisabled,
  onDownloadButtonClick,
}: DocumentDownloadProps) => {
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);
  const [checkboxDirty, setCheckboxDirty] = useState<boolean>(false);
  const [downloadButtonClicked, setDownloadButtonClicked] = useState<boolean>(false);
  useEffect(() => {
    onValidate(checkboxChecked, downloadButtonClicked);
  }, [checkboxChecked, checkboxDirty, downloadButtonClicked, onValidate]);

  const toggleCheckbox = () => {
    setCheckboxDirty(true);
    setCheckboxChecked(!checkboxChecked)
  };
  const onButtonClick = () => {
    setDownloadButtonClicked(true);
    if(onDownloadButtonClick) {
      onDownloadButtonClick();
    }
  }
  return (
    <>
      <div>
        <h2>EU-Vorgabe: Beratungspflicht</h2>
        <p>
          Seit Februar 2018 gilt auch für Vertragsabschlüsse per Internet: Wir
          müssen Sie vorher beraten und dies dokumentieren. Sie können auch
          schnell und dafür ohne Beratung abschließen. Dann brauchen wir vor dem
          Abschluss Ihren Beratungsverzicht.
        </p>
      </div>
      <div>
        <Checkbox
          data-test-id='DocumentDownload-Checkbox'
          label={
            'Ich verzichte für diesen Vertragsabschluss auf eine persönliche Beratung sowie deren Dokumentation. Dies kann sich evtl. auf die Geltendmachung von Schadensersatzansprüchen nachteilig auswirken.'
          }
          checked={checkboxChecked}
          onChange={toggleCheckbox}
        />
      </div>
      {!checkboxChecked && checkboxDirty && (
        <MessageBox type='error'>
          Bitte bestätigen Sie, dass Sie für diesen Abschluss keine Beratung
          benötigen.
        </MessageBox>
      )}
      <div
        style={{
          height: '1px',
          margin: '20px 0',
          backgroundColor: '#dddddd',
        }}
      />
      <div>
        <h2>Vertragsunterlagen</h2>
        <p>
          Bitte speichern Sie vor dem Online-Abschluss die Vertragsunterlagen
          und Informationen als PDF.
        </p>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <StepButton
            data-test-id='DocumentDownload-Button'
            type='button'
            text='Herunterladen'
            style={{ width: '250px' }}
            note={
              'Laden Sie erst die Vertragsunterlagen herunter und schließen Sie dann online ab.'
            }
            step={1}
            onClick={onButtonClick}
            loading={downloadButtonLoading}
            disabled={ !checkboxChecked || downloadButtonLoading || downloadButtonDisabled }
          />
        </div>
      </div>
    </>
  );
};
DocumentDownload.defaultProps = {
  loading: false,
  disableDownloadButton: false,
  onDownloadButtonClick: () => {}
}
export default DocumentDownload;
