import Tarifzahlweise from '../messaging/model/Zahlweise';
import { Zahlweise } from '@eg/idd-components';

const mapZahlweiseToDisplaytext = (zw: Tarifzahlweise): string => {
    const moeglicheZahlweisen = new Map<Tarifzahlweise, string>([
        [Tarifzahlweise.MONATLICH, 'monatlicher'],
        [Tarifzahlweise.VIERTELJAHRLICH, 'vierteljährlicher'],
        [Tarifzahlweise.HALBJAHRLICH, 'halbjährlicher'],
        [Tarifzahlweise.JAHRLICH, 'jährlicher'],
        [Tarifzahlweise.EINMALZAHLUNG, 'einmaliger'],
        [Tarifzahlweise.SAISONBEITRAG, 'saisonaler'],
    ]);

    return moeglicheZahlweisen.get(zw) as string;
};

const mapZahlweiseToIddZahlweise = (zw: Tarifzahlweise): Zahlweise => {
    const moeglicheZahlweisen = new Map<Tarifzahlweise, Zahlweise>([
        [Tarifzahlweise.MONATLICH, Zahlweise.MONATLICH],
        [Tarifzahlweise.VIERTELJAHRLICH, Zahlweise.VIERTELJAEHRLICH],
        [Tarifzahlweise.HALBJAHRLICH, Zahlweise.HALBJAEHRLICH],
        [Tarifzahlweise.JAHRLICH, Zahlweise.JAEHRLICH],
        [Tarifzahlweise.EINMALZAHLUNG, Zahlweise.EINMALZAHLUNG],
        [Tarifzahlweise.SAISONBEITRAG, Zahlweise.SAISONVERTRAG]
    ]);

    return moeglicheZahlweisen.get(zw) as Zahlweise;
};

export {
    mapZahlweiseToDisplaytext,
    mapZahlweiseToIddZahlweise
};
