import * as React from 'react';
import FormSection from '@eg/elements/FormSection';
import ControlWithHint from '@eg/elements/ControlWithHint';
import Checkbox from '@eg/elements/Checkbox';
import * as ReactDOM from 'react-dom';
import { ChangeEvent } from 'react';

export interface ConsentBoxProps {
    index: number;
    title: string;
    text: string;
    errorMsg: string;
    valid: boolean;
    onCheck: (key: number, checked: boolean) => void;
    triggerValidation: boolean;
}

interface ConsentBoxState {
    touched: boolean;
}

export class ConsentBox extends React.Component<ConsentBoxProps, ConsentBoxState> {
    // tslint:disable-next-line
    private checkBoxComponent: any;

    handleChange = (checked: boolean) => {
        this.setState({ touched: true });
        this.props.onCheck(this.props.index, checked);
    }

    constructor(props: ConsentBoxProps, state: ConsentBoxState) {
        super (props, state);

        this.state = {
            touched: false
        };
    }

    componentDidUpdate(prevProps: ConsentBoxProps, prevState: ConsentBoxState) {
        if (!this.state.touched && this.props.triggerValidation) {
            this.setState({ touched: true });
        }
        if (this.state.touched && (!this.props.valid) && this.props.errorMsg) {
            let node = ReactDOM.findDOMNode(this.checkBoxComponent) as Element;

            if (node && node.scrollIntoView) {
                node.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth'
                });
            }
        }
    }

    render() {
        return(
            <FormSection data-component-id={`consent-area-${this.props.title}`}>
                <h1 style={{paddingBottom: '10px'}}>{this.props.title}</h1>

                {/* should not show error if checkbox was never touched */}
                <ControlWithHint error={this.state.touched && (!this.props.valid) && this.props.errorMsg}>
                    <Checkbox
                        label={this.props.text}
                        ref={(element) => this.checkBoxComponent = element}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => this.handleChange(e.target.checked)}
                        data-component-id={`checkbox-${this.props.title}`}
                    />
                </ControlWithHint>
            </FormSection>
        );
    }
}
