import { AppState } from './AppState';

export const setConsentValidity = (state: AppState, index: number, checked: boolean) => {
    const consents = state.consents.map((validState: boolean, i: number) => {
        return  i === index ? checked : validState;
    });

    return {...state, consents};
};

export const getValid = (consents: boolean[], index: number) => {
    return consents[index];
};
