import * as React from 'react';
import FormSection from '@eg/elements/FormSection';
import Checkbox from '@eg/elements/Checkbox';
import RadioGroupRow from '@eg/elements/RadioGroupRow';
import Radio from '@eg/elements/Radio';
import TooltipIcon from '@eg/elements/TooltipIcon';
import ControlWithHint from '@eg/elements/ControlWithHint';
import { Callbacks } from '../messaging/model/Callbacks';
import { SchweigepflichtEntbindungData, SchweigepflichtEntbindungTyp } from '../types/SchweigepflichtEntbindung';
import * as ReactDOM from 'react-dom';
import callOptionalFunction from '../utility/FunctionHelper';

interface SchweigepflichtEntbindungCompState {
    schweigepflichtEntbindung: boolean;
    touched: boolean;
}

interface SchweigepflichtEntbindungCompProps {
    schweigepflichtEntbindung: SchweigepflichtEntbindungData;
    onChange: (data: SchweigepflichtEntbindungData) => void;
    triggerValidation: boolean;
    callbacks: Callbacks;
}

export class SchweigepflichtEntbindungComp extends React.Component<SchweigepflichtEntbindungCompProps, SchweigepflichtEntbindungCompState> {
    // tslint:disable-next-line
    private checkBoxComponent: any;

    constructor(props: SchweigepflichtEntbindungCompProps, state: SchweigepflichtEntbindungCompState) {
        super(props, state);
        const schweigepflichtEntbindung = props.schweigepflichtEntbindung;
        this.state = {
            schweigepflichtEntbindung: !!schweigepflichtEntbindung.schweigepflichtEntbindungTyp || !!schweigepflichtEntbindung.schweigepflichtEntbindungTod,
            touched: false,
        };
    }

    componentDidUpdate(prevProps: SchweigepflichtEntbindungCompProps, prevState: SchweigepflichtEntbindungCompState) {
        if (!this.state.touched && this.props.triggerValidation) {
            this.setState({ touched: true });
        }
        if (this.state.touched && !this.state.schweigepflichtEntbindung) {
            let node = ReactDOM.findDOMNode(this.checkBoxComponent) as Element;

            if (node && node.scrollIntoView) {
                node.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth'
                });
            }
        }
    }

    render() {
        return (
            <FormSection
                heading="Schweigepflichtentbindungserklärung"
                data-component-id="block-schweigepflichtentbindung"
                tooltip={
                   <div>
                       <p>
                       Die ERGO Vorsorge Lebensversicherung AG beurteilt vor der Antragsannahme die zu versichernden
                           Risiken. Im Rahmen dessen kann es notwendig sein, z. B. bei Ärzten Ihre Gesundheitsdaten
                           abzufragen. Zur Prüfung der Leistungspflicht kann es zudem notwendig sein, Angaben zu Ihrem
                           Gesundheitszustand zu prüfen, die Sie selbst gemacht haben oder die sich aus Ihren Unterlagen
                           ergeben (z. B. Rechnungen, Verordnungen, Gutachten).
                       </p>
                        <p>
                           Diese Überprüfung erfolgt nur, soweit es zur Erfüllung der Pflichten des Versicherers
                            erforderlich ist. Dafür benötigt die ERGO Vorsorge Lebensversicherung AG Ihre Einwilligung
                            zur Erhebung und Verwendung von Daten einschließlich einer Schweigepflichtentbindung. Eine
                            solche ist auch für die entsprechenden Stellen erforderlich, falls im Rahmen der Abfragen
                            Gesundheitsdaten oder weitere nach § 203 StGB (Strafgesetzbuch) geschützte Informationen
                            weitergegeben werden müssen.
                       </p>
                   </div>
                }
            >
                <ControlWithHint
                    error={this.state.touched && !this.state.schweigepflichtEntbindung && 'Bitte bestätigen Sie Ihre Einwilligung.'}
                    data-component-id="schweigepflichtEntbindung_checkbox_hint"
                >
                    <Checkbox
                        label="Ich gebe gegenüber der ERGO Vorsorge Lebensversicherung AG eine
                            Schweigepflichtentbindungserklärung zur Erhebung und Verwendung von Daten ab."
                        data-component-id="schweigepflichtEntbindung_checkbox"
                        ref={(element) => this.checkBoxComponent = element}
                        checked={this.state.schweigepflichtEntbindung}
                        onChange={(e:  React.ChangeEvent<HTMLInputElement>) => {
                            this.setState({
                                schweigepflichtEntbindung: e.target.checked,
                                touched: true
                            });
                            this.props.onChange({
                                schweigepflichtEntbindungTyp: undefined,
                                schweigepflichtEntbindungTod: undefined
                            });
                            callOptionalFunction(this.props.callbacks.onSEECheckboxClicked);
                        }}
                    />
                </ControlWithHint>
                {this.state.schweigepflichtEntbindung &&
                <div style={{paddingLeft: '2em'}}>
                    <b>Ich erteile die Schweigepflichtentbindungserklärung für den Erlebensfall</b>
                    <TooltipIcon isModal={true}>
                        <div>
                            <h4>Pauschal</h4>
                            <p>
                            Soweit es für die Risikobeurteilung oder für die Leistungsfallprüfung erforderlich ist,
                                darf die ERGO Vorsorge Lebensversicherung AG meine Gesundheitsdaten verwenden.
                                Dazu darf sie sie erheben bei Ärzten, Pflegepersonen sowie bei Bediensteten von
                                Krankenhäusern, sonstigen Krankenanstalten, Pflegeheimen, Personenversicherern,
                                gesetzlichen Krankenkassen, Berufsgenossenschaften und Behörden.
                            </p>
                            <p>
                            Diese befreie ich von ihrer Schweigepflicht, soweit meine Gesundheitsdaten aus
                                Untersuchungen, Beratungen, Behandlungen sowie Versicherungsanträgen und -verträgen
                                aus einem Zeitraum von bis zu 10 Jahren vor der Antragstellung an die ERGO Vorsorge
                                Lebensversicherung AG weitergeleitet werden.
                            </p>
                            <p>
                            Ich bin außerdem damit einverstanden, dass in diesem Zusammenhang die ERGO Vorsorge
                                Lebensversicherung AG meine Gesundheitsdaten an diese Stellen weitergibt, soweit
                                erforderlich. Deren Mitarbeiter befreie ich insoweit ebenfalls von ihrer Schweigepflicht.
                            </p>
                            <p>
                            Vor jeder Datenerhebung werde ich informiert, von wem und zu welchem Zweck die Daten
                                erhoben werden sollen. Außerdem werde ich darauf hingewiesen, dass ich widersprechen
                                und die erforderlichen Unterlagen selbst besorgen kann.
                            </p>

                            <h4>Einzelfall</h4>

                            <p>
                            Die ERGO Vorsorge Lebensversicherung AG soll mich in jedem Einzelfall informieren, von wem
                            sie zu welchem Zweck eine Auskunft benötigt. Ich entscheide dann jeweils,
                            </p>
                            <ul>
                                <li>ob sie meine Gesundheitsdaten erheben und verwenden darf,</li>
                                <li>ob ich dazu die genannten Personen oder Einrichtungen sowie deren Mitarbeiter von ihrer Schweigepflicht entbinde und </li>
                                <li>ob ich in die Weiterleitung meiner Gesundheitsdaten an die ERGO Vorsorge Lebensversicherung AG einwillige. </li>
                            </ul>
                            <p>
                            Wenn nicht, besorge ich die Daten selbst. Mir ist bewusst, dass das zu einer Verzögerung der
                                Antragsbearbeitung oder der Prüfung der Leistungspflicht führen kann.
                            </p>
                            <p>
                            Soweit sich diese Erklärungen auf meine Angaben bei der Antragstellung beziehen, gelten sie
                                für einen Zeitraum von 5 Jahren nach Vertragsschluss.
                            </p>
                            <p>
                            Sie gelten bis zu 10 Jahre nach Vertragsschluss, falls sich nach Vertragsschluss für die
                                ERGO Vorsorge Lebensversicherung AG konkrete Anhaltspunkte dafür ergeben, dass ich bei
                                der Antragstellung vorsätzlich unrichtige oder unvollständige Angaben gemacht habe,
                                die die Risikobeurteilung beeinflusst haben.
                            </p>
                        </div>
                    </TooltipIcon>
                    <RadioGroupRow
                        data-component-id="schweigepflichtEntbindung_radio"
                        name="schweigepflichtentbindung_radio"
                        label=""
                        error={this.props.triggerValidation && !this.props.schweigepflichtEntbindung.schweigepflichtEntbindungTyp && "Bitte bestätigen Sie Ihre Einwilligung."}
                        value={this.props.schweigepflichtEntbindung.schweigepflichtEntbindungTyp}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const schweigepflichtEntbindungTyp = e.target.value as SchweigepflichtEntbindungTyp;
                            this.props.onChange({
                                schweigepflichtEntbindungTyp,
                                schweigepflichtEntbindungTod: this.props.schweigepflichtEntbindung.schweigepflichtEntbindungTod
                            });
                            this.handleSEETypeSelectionCallbacks(schweigepflichtEntbindungTyp);
                        }}
                    >
                        <Radio
                            label="Pauschal"
                            value={SchweigepflichtEntbindungTyp.PAUSCHAL}
                        />
                        <Radio
                            label="Einzelfall"
                            value={SchweigepflichtEntbindungTyp.EINZELFALL}
                        />
                    </RadioGroupRow>
                    <b>Ich erteile die Schweigepflichtentbindungserklärung für den Leistungsfall (Tod)</b>
                    <ControlWithHint>
                        <Checkbox
                            data-component-id="schweigepflichtEntbindungTod_checkbox"
                            label="Für den Fall meines Todes erteile ich eine pauschale Einwilligung zur Erhebung meiner
                            Gesundheitsdaten bei Dritten für Risikobeurteilung und zu Prüfung der Leistungspflicht im
                            Todesfall."
                            checked={this.props.schweigepflichtEntbindung.schweigepflichtEntbindungTod}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                this.props.onChange({
                                    schweigepflichtEntbindungTod: e.target.checked,
                                    schweigepflichtEntbindungTyp: this.props.schweigepflichtEntbindung.schweigepflichtEntbindungTyp
                                });
                                callOptionalFunction(this.props.callbacks.onSEECheckboxEntbindungClicked);
                            }}
                        />
                    </ControlWithHint>
                </div>
                }
            </FormSection>
        );
    }

    private handleSEETypeSelectionCallbacks = (schweigepflichtEntbindungTyp: SchweigepflichtEntbindungTyp) => {
        switch (schweigepflichtEntbindungTyp) {
            case SchweigepflichtEntbindungTyp.EINZELFALL:
                callOptionalFunction(this.props.callbacks.onSEEOptionEinzelfallClicked);
                break;
            case SchweigepflichtEntbindungTyp.PAUSCHAL:
                callOptionalFunction(this.props.callbacks.onSEEOptionPauschalClicked);
                break;
            default:
                break;
        }
    }
}
