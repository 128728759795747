import Checkbox from '@eg/elements/Checkbox'
import MessageBox from '@eg/elements/MessageBox'
import StepButton from '@eg/elements/StepButton'
import React, { useState } from 'react'

export interface IddMockProps {
  onValidate: (isCheckboxClicked: boolean, isDocumentDownloaded: boolean) => void
  onError: (e: Error) => void
}
export const IddMock = (props: IddMockProps) => {
  console.log('Using mocked idd-components');
  const [firstCheckboxClick, setFirstCheckboxClick] = useState<boolean>(true);
  const [checkboxClicked, setCheckboxClicked] = useState<boolean>(false);
  const [checkboxError, setCheckboxError] = useState<boolean>(false);

  const validateCheckBox = () => {
    setFirstCheckboxClick(false);
    !firstCheckboxClick && setCheckboxClicked(!checkboxClicked);
  };
  React.useEffect(() => {
    setCheckboxError(checkboxClicked);
  }, [checkboxClicked, setCheckboxError]);
  return (
    <>
      <div>
        <h2>EU-Vorgabe: Beratungspflicht</h2>
        <p>
          Seit Februar 2018 gilt auch für Vertragsabschlüsse per Internet: Wir
          müssen Sie vorher beraten und dies dokumentieren. Sie können auch
          schnell und dafür ohne Beratung abschließen. Dann brauchen wir vor dem
          Abschluss Ihren Beratungsverzicht.
        </p>
      </div>
      <div>
        <Checkbox
          label={
            'Ich verzichte für diesen Vertragsabschluss auf eine persönliche Beratung sowie deren Dokumentation. Dies kann sich evtl. auf die Geltendmachung von Schadensersatzansprüchen nachteilig auswirken.'
          }
          onChange={validateCheckBox}
        />
      </div>
      {!firstCheckboxClick && checkboxError && (
        <MessageBox type="error">
          Bitte bestätigen Sie, dass Sie für diesen Abschluss keine Beratung
          benötigen.
        </MessageBox>
      )}
      <div
        style={{
          height: '1px',
          margin: '20px 0',
          backgroundColor: '#dddddd'
        }}
      />
      <div>
        <h2>Vertragsunterlagen</h2>
        <p>
          Bitte speichern Sie vor dem Online-Abschluss die Vertragsunterlagen
          und Informationen als PDF.
        </p>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <StepButton
            id="herunterladen"
            type="button"
            text="Herunterladen"
            style={{width: '250px'}}
            note={
              'Laden Sie erst die Vertragsunterlagen herunter und schließen Sie dann online ab.'
            }
            step={1}
            onClick={() => props.onValidate(true, true)}
            disabled={
              (firstCheckboxClick && !checkboxClicked) || checkboxClicked
            }
          />
        </div>
      </div>
    </>
  )
}
