import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { messaging } from './messaging/messaging';
import { CheckoutInput } from './messaging/model/CheckoutInput';
import App from './App';
import ErrorBoundary from './ErrorBoundary'; 
import { mapToPdfRequest } from './mapper/mapper';
import { validateInput } from './messaging/Guard';

// next line: this is only for local development. during the build step a main.xyz.css file will be deployed, but that won't
// be copied to the docker container. instead, the 'specialized' css files from from @eg/elements will be copied
// reason for this workaround: dynamic css module imports are not supported.
import '@eg/elements/styles/styles.css';
import { ExternalSubcomponent } from './types/ExternalSubcomponent';

const getMountingPoint = () => {
    return document.getElementById('root-checkout') as HTMLElement;
};

let externalSubcomponents: ExternalSubcomponent[] | undefined;

messaging.listenToRenderCheckout((event: CustomEvent) => {
    const input: CheckoutInput = validateInput(event.detail);
    externalSubcomponents = input.model.extSubcomponents;

    const loadCss = process.env.NODE_ENV === 'production'
        ? (theme: string) => {
            const baseUrl = '';
            const apiRoot = input.backendHost ? input.backendHost : baseUrl;
            const link = document.createElement('link');
            const href = `${apiRoot}/static/css/${theme}.css`;
            link.href = href;
            link.rel = 'stylesheet';
            document.head!.appendChild(link);

            const resolverFx = (resolve: (value?: void | PromiseLike<void>) => void) => {
                // set up an interval that checks if the stylesheet has already been loaded to avoid FOUC
                const interval = setInterval(() => {
                    const length = document.styleSheets.length;
                    for (let i = 0; i < length; i++) {
                        const css = document.styleSheets[i];
                        if (css.href === href) {
                            resolve();
                            clearInterval(interval);
                            break;
                        }
                    }
                },                           250);
            };
            return new Promise(resolverFx);
        }
        // just immediately resolve in development
        : () => Promise.resolve();
    const schweigepflichtEntbindung = input.model.schweigepflichtEntbindung || {display: false};
    const iddWithoutFormsection = input.model.iddWithoutFormsection || false;

    loadCss(input.theme as string).then(
        () => {
            ReactDOM.render(
                <ErrorBoundary onError={input.callbacks.onError}>
                    <App
                        theme={input.theme}
                        versicherungsnehmer={input.model.versicherungsnehmer}
                        tarifdaten={input.model.tarifdaten}
                        blocks={input.model.blocks}
                        extSubcomponents={input.model.extSubcomponents}
                        beitragsdaten={input.model.beitragsdaten}
                        messaging={messaging}
                        pdfRequest={mapToPdfRequest(input)}
                        documentDownload={input.documentDownload}
                        tracking={input.tracking}
                        callbacks={input.callbacks}
                        consents={input.model.consentBoxes}
                        sparte={input.model.sparte}
                        schweigepflichtEntbindung={schweigepflichtEntbindung}
                        antrag={!!input.model.antrag}
                        iddWithoutFormsection={iddWithoutFormsection}
                        backendHost={input.backendHost}
                    />
                </ErrorBoundary>,
                getMountingPoint());
        }
    );
});

messaging.listenToUnmountCheckout(() => {
    // unmount every "injected" frontend microservice
    if (externalSubcomponents) {
        externalSubcomponents.forEach(extSubComp => {
            ReactDOM.unmountComponentAtNode(document.getElementById(extSubComp.mountingPoint) as HTMLElement);
        });
    }

    ReactDOM.unmountComponentAtNode(getMountingPoint());
});
