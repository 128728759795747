import { CheckoutInput } from './model/CheckoutInput';

export const validateInput = (input: CheckoutInput): CheckoutInput => {
    if (typeof input.model.beitragsdaten.onBeitragsdatenChanged === 'function') {
        return input;

    } else if (!input.model.beitragsdaten.onBeitragsdatenChanged) {
        return input;

    } else {
        throw new Error('CheckoutInput: onBeitragsdatenChanged not a function or undefined!');
    }
};
