import * as React from 'react';
import MessageBox from '@eg/elements/MessageBox';
import getAnrede from '../utility/anrede';
import { Sparte } from '../types/Sparte';

interface HeaderInfoProps {
  anrede?: string;
  titel?: string | undefined;
  vorname?: string;
  nachname?: string;
  sparte: Sparte;
  antrag: boolean;
}

const HeaderInfo = (props: HeaderInfoProps) => {
  const titel = props.titel ? props.titel : undefined;

  const anrede = (props.anrede && props.vorname && props.nachname)
      ? getAnrede(props.anrede, titel, props.vorname, props.nachname)
      : undefined;

  return (
    <MessageBox>
      {anrede && (<p style={{ fontSize: '1.2em', fontWeight: 700, marginBottom: '.5em' }}>{anrede}</p>)}

      <span>
        {anrede ? 'b' : 'B'}itte prüfen Sie, ob Ihre Angaben richtig und
        vollständig sind.
        <br/>
        {!props.antrag &&
        <>
          Mit Ihrem Klick auf&nbsp;
          <strong style={{fontWeight: 700}}>
            &quot;Online abschließen&quot;
          </strong>
          &nbsp;werden Ihre Daten verbindlich an {`die ${props.sparte}`} übertragen. Innerhalb weniger
          Tage erhalten Sie dann Ihre Unterlagen per Post.
        </>
        }
        {props.antrag &&
        <>
          Mit Ihrem Klick auf&nbsp;
          <strong style={{fontWeight: 700}}>
            &quot;Online anfordern&quot;
          </strong>
          &nbsp;werden Ihre Daten an {`die ${props.sparte}`} zur Erstellung eines verbindlichen Angebots gesendet.
          Dieses Angebot erhalten Sie innerhalb weniger Tage per Post. Ihm liegen vor&shy;be&shy;reitete Unterlagen bei, die
          Sie bitte unterschrieben an ERGO zurückschicken. Erst dann kommt der Versicherungsvertrag zustande
          und Sie erhalten den gewünschten Versicherungsschutz.
          <br/>
          <br/>
          Übrigens: Ein Risiko gehen Sie nicht ein. Sie haben nach Vertragsabschluss die Möglichkeit,
          Ihre Annahmeerklärung in&shy;ner&shy;halb von 30 Tagen zu widerrufen.
        </>
        }
      </span>
    </MessageBox>

  );
};

export default HeaderInfo;
