import * as React from 'react';
import { FormSection } from '@eg/elements/FormSection';
import EditIcon from '@eg/elements/components/Icons/EditIcon';
import Button from '@eg/elements/Button';

interface MountingPointCompProps {
    headline?: string;
    mountingPoint: string;
    onEdit: () => void;
}

const MountingPointComp: React.StatelessComponent<MountingPointCompProps> = (props: MountingPointCompProps) => {
    return (
        <FormSection
            heading={props.headline}
            data-component-id={`mountingpoint_${props.mountingPoint}`}
            key={props.mountingPoint}
        >
            <div id={props.mountingPoint}/>

            <Button
                type="button"
                iconLeft={<EditIcon/>}
                variant="text-link"
                data-component-id={`edit-${props.mountingPoint}`}
                onClick={props.onEdit}
            >
                Angaben ändern
            </Button>

        </FormSection>
    );
};

export default MountingPointComp;
