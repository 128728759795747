import * as React from 'react';
import { Versicherungsnehmer } from '../types/Versicherungsnehmer';
import FormSection from '@eg/elements/FormSection';
import SummaryList from '@eg/elements/SummaryList';
import Button from '@eg/elements/Button';
import EditIcon from '@eg/elements/components/Icons/EditIcon';

interface VersicherungsnehmerProps {
    data: Versicherungsnehmer;
}

const VersicherungsnehmerComp = (props: VersicherungsnehmerProps) => {
    let anrede = 'Persönliche Daten des Versicherungsnehmers';
    if (props.data.titelZusatz) {
        anrede += ' ' + props.data.titelZusatz;
    }
    return (
        <FormSection heading={anrede} data-component-id="block-persoenliche-daten-vn">
            <SummaryList>
                <dt>Anrede</dt>
                <dd>{props.data.anrede}</dd>

                {props.data.titel && [
                    <dt key={'titelLabel'}>Titel</dt>,
                    <dd key={'titelValue'} data-component-id="titelValue">{props.data.titel}</dd>
                ]}

                <dt>Vorname</dt>
                <dd>{props.data.vorname}</dd>

                <dt>Nachname</dt>
                <dd>{props.data.nachname}</dd>

                <dt>Geburtsdatum</dt>
                <dd>{props.data.geburtsdatum}</dd>

                <dt>Straße/Hausnummer</dt>
                <dd>{props.data.adresse.strasse} {props.data.adresse.hausnummer}</dd>

                <dt>PLZ/Wohnort</dt>
                <dd>{props.data.adresse.plz} {props.data.adresse.ort}</dd>

                {props.data.geburtsort && [
                    <dt key={'geburtsortLabel'}>Geburtsort</dt>,
                    <dd key={'geburtsortValue'} data-component-id={'geburtsortValue'}>{props.data.geburtsort}</dd>
                ]}

                {props.data.staatsangehoerigkeit && [
                    <dt key={'staatsangehoerigkeitLabel'}>Staatsangehörigkeit</dt>,
                    <dd key={'staatsangehoerigkeitValue'} data-component-id={'staatsangehoerigkeitValue'}>{props.data.staatsangehoerigkeit}</dd>
                ]}

                {props.data.eMail && [
                    <dt key={'emailLabel'}>E-Mail</dt>,
                    <dd key={'emailValue'} data-component-id={'emailValue'}>{props.data.eMail}</dd>
                ]}

                {props.data.vorwahl && <dt>Telefonnummer</dt>}
                {props.data.vorwahl && <dd>{props.data.vorwahl} {props.data.rufnummer}</dd>}
            </SummaryList>
            <Button
                type="button"
                iconLeft={<EditIcon/>}
                variant="text-link"
                data-component-id={'CheckData-EditLinkForStep-VN'}
                onClick={props.data.onVersicherungsnehmerChanged}
            >
                Angaben ändern
            </Button>
        </FormSection>
    );
};

export default VersicherungsnehmerComp;
