import { CheckoutInput } from '../messaging/model/CheckoutInput';
import { PdfRequest } from '@eg/idd-components';

const mapToPdfRequest = (source: CheckoutInput): PdfRequest => {
        const pdfRequestProps = source.additionalIddPdfRequestProperties;

        // Nicht schoen, aber bis sichergestellt ist dass alle Tarife die Flag enthalten die eleganteste Loesung
        const vermittlerPDFMitschicken = !!pdfRequestProps.vermittlerPDFMitschicken;

        switch (source.additionalIddPdfRequestProperties.tarif) {
            case 'SZS':
            case 'SZL':
                return {
                    vermittlerPDFMitschicken: vermittlerPDFMitschicken,
                    tarifSzPdfRequest: {
                        angebotsId: pdfRequestProps.businessId
                    }
                };

            case 'KFZ_SPEED':
                return {
                  vermittlerPDFMitschicken: vermittlerPDFMitschicken,
                  kfzSpeedRequest: {
                    angebotsId: pdfRequestProps.businessId,
                  }
            };

            case 'RLV':
                return {
                    vermittlerPDFMitschicken: vermittlerPDFMitschicken,
                    rlvDiveRequest: {
                        angebotsId: pdfRequestProps.businessId
                    }
                };

            default:
                throw new Error(
                    `passed tarif '${
                        source.additionalIddPdfRequestProperties.tarif
                        }' is unknown.`
                );
        }
    };

export { mapToPdfRequest };
