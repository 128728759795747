import * as React from 'react';
import Button from '@eg/elements/Button';
import EditIcon from '@eg/elements/components/Icons/EditIcon';
import PremiumBox from '@eg/elements/PremiumBox';
import Price from '@eg/elements/Price';
import { Beitragsdaten } from '../types/Beitragsdaten';
import { mapZahlweiseToDisplaytext } from '../mapper/zahlweiseMapper';
import Annotation from './Annotation';

interface BeitragsdatenCompProps {
    data: Beitragsdaten;
}

const BeitragsdatenComp: React.StatelessComponent<BeitragsdatenCompProps> = (props: BeitragsdatenCompProps) => {

    const annotation: JSX.Element| null = props.data.fussnote ? <Annotation order={1}/> : null;

    return (
        <div>
            <PremiumBox
                label={<strong>Zu zahlender {mapZahlweiseToDisplaytext(props.data.zahlweise)} Beitrag
                    {annotation} </strong>}
                price={<Price value={props.data.beitrag}/>}
            />
            {props.data.onBeitragsdatenChanged && (<Button
                type="button"
                iconLeft={<EditIcon/>}
                variant="text-link"
                data-component-id={'CheckData-EditLinkForStep-Tarifdaten'}
                onClick={props.data.onBeitragsdatenChanged}
            >
                Angaben ändern
            </Button>)}
        </div>
    );
};

export default BeitragsdatenComp;
