export interface SchweigepflichtEntbindung {
    display: boolean;
    input?: SchweigepflichtEntbindungData;
}

export interface SchweigepflichtEntbindungData {
    schweigepflichtEntbindungTod?: boolean;
    schweigepflichtEntbindungTyp?: SchweigepflichtEntbindungTyp;
}

export enum SchweigepflichtEntbindungTyp {
    PAUSCHAL = 'PAUSCHAL',
    EINZELFALL = 'EINZELFALL'
}
