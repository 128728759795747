import * as React from 'react';
import { Block } from '../types/Block';
import FormSection from '@eg/elements/FormSection';
import Button from '@eg/elements/Button';
import SummaryList from '@eg/elements/SummaryList';
import EditIcon from '@eg/elements/components/Icons/EditIcon';

interface BlockCompProps {
    block: Block;
}

const BlockComp: React.StatelessComponent<BlockCompProps> = (props: BlockCompProps) => {
    return (
        <FormSection
            data-component-id={`block-${props.block.name}`}
            heading={props.block.name}
            key={props.block.name}
        >
            <SummaryList>
                {Object.keys(props.block.values).map((key: string) => {
                    return [
                        <dt key={key}>{key}</dt>,
                        <dd key={props.block.values[key]}>{props.block.values[key]}</dd>
                    ];
                })}
            </SummaryList>
            {props.block.onClick &&  <Button
                type="button"
                iconLeft={<EditIcon/>}
                variant="text-link"
                data-component-id={'CheckData-EditLinkForStep-' + props.block.name}
                onClick={props.block.onClick}
            >
                Angaben ändern
            </Button>}
        </FormSection>
    );
};

export default BlockComp;