import * as React from 'react';
import { ForceDownload, PdfRequest } from '@eg/idd-components';
import callOptionalFunction from '../utility/FunctionHelper';

interface IddProps {
  onValidate: (
    isCheckboxClicked: boolean,
    isDocumentDownloaded: boolean
  ) => void;
  onError: (e: Error) => void;
  isIddErrorMessageDisplayed: boolean;
  pdfRequest: PdfRequest;
  antrag: boolean;
  onCheckedBeratungsverzicht?: () => void;
  onDownloadedPdf?: () => void;
  backendHost?: string;
}

const Idd = (props: IddProps) => (
  <div style={{ marginTop: '20px' }}>
    <ForceDownload
      onValidate={props.onValidate}
        // tslint:disable-next-line:no-empty
      onButtonClick={() => { callOptionalFunction(props.onDownloadedPdf); }}
      onCheckboxClick={() => { callOptionalFunction(props.onCheckedBeratungsverzicht); }}
      onError={props.onError}
      showValidation={props.isIddErrorMessageDisplayed}
      env={process.env.NODE_ENV === 'development' ? 'test' : ''}
      pdfRequest={props.pdfRequest}
      stepButtonWidth={250}
      antrag={props.antrag}
      backendHost={props.backendHost}
    />
  </div>
);

export default Idd;
